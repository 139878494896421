import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import Estructura from './componentes/estructura';
import Comite from './componentes/comite'
import Organizacion from './componentes/Organizacion';
import AvanceDto  from './componentes/GeneralDto';
import Incidencia from './componentes/incidencias';
import Mapa from './componentes/mapa'
import AvanceTitular from './componentes/avance-titular';
import Prospecto from './componentes/prospecto';
import MapaIncidencia from './componentes/MapaIncidencia';

function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
     // console.log("sin el tema");
    }
    else
    {  console.log("cargando el tema");
      //console.log(JSON.parse(auxColor));
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>
        <ClienteProvider>
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />                                                                                                                                                                                                       
                <Route exact path="/observador" component={Estructura}  />                                
                <Route exact path="/comite" component={Comite}  />                 
                <Route exact path="/organizacion" component={Organizacion}  /> 
                <Route exact path="/general-distrito" component={AvanceDto}  /> 
                <Route exact path="/incidencia" component={Incidencia}  /> 
                <Route exact path="/mapa-secc" component={Mapa}  />
                <Route exact path="/mapa-incidencia" component={MapaIncidencia}  />
                <Route exact path="/militante" component={Prospecto}  />
                <Route exact path="/avance-titular" component={AvanceTitular}  />
               {/* */}
                <Route path="*" component={  Login } />
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router>
        </ClienteProvider>
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;