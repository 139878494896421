import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '29rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'.5rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '28rem',
      minHeight: '25rem',
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '28rem',
        maxHeight: '25rem',
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[                    
    {
      id:1, titulo:"Municipio", alinear:'center'
    },      
    {
      id:5, titulo:"Sección", alinear:'center'
    },      
    {
      id:2, titulo:"OBS", alinear:'center'
    },      
    //{ id:3, titulo:"RG", alinear:'center'},      
    //{ id:4, titulo:"RC", alinear:'center'},      
    { id:6, titulo:"Validado", alinear:'center' },      
    { id:7, titulo:"Pendiente", alinear:'center' },    
    { id:8, titulo:"Rechazado", alinear:'center' },          
    
  ];

const alturaCont = window.innerHeight<937 ? window.innerHeight-250 : window.innerHeight-270

export default function TablaMun({listAvMun,registro, setRegistro}){    
  const classes = useStyles();    
  const [promotor, setPromotor] = useState({nombre:""})
  const [seleccion, setSeleccion] = useState(null)
  const [datos,setDatos] = useState([]);     
  const [totales,setTotales] = useState({vali:0,pendiente:0,recha:0,observ:0});     
  const{vali,pendiente, recha,observ }=totales
  const auxCol=process.env.REACT_APP_Fondo_Color
  const {nombre} = promotor;    
  useEffect(()=>{
    if (listAvMun.length!==0 &&listAvMun[0].avc_dto_municipio) {
      //console.log(listAvMun);
      setDatos(listAvMun[0].avc_dto_municipio)  
      let auxVal=0,auxPen=0, auxRec=0, auxObs=0
      listAvMun[0].avc_dto_municipio.forEach(element => {
        auxVal+=element.Valido
        auxPen+=element.Pendiente
        auxRec+=element.Rechazado
        auxObs+=element.TpObsvr
        
      });
      setTotales({vali:auxVal,pendiente:auxPen,recha:auxRec,observ:auxObs})
    }else
    {setTotales({vali:0,pendiente:0,recha:0,observ:0})
      setDatos([])
    }
    

    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAvMun])          

  const onChangeTable =(regis)=>{                       
    setRegistro(regis)
  }

  function filtro(){        
    let auxCadena=nombre.trim();       
    let info=[];                        
    let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
    info=aux 
    let auxNum=0;
      if (aux!==null||aux!=='') 
      {
      aux.forEach((mun)=>
      { if (mun.nombreReg!=='EDOMEX')
        {   
          auxNum++;
        }                
      })
    }         
    return TablaEstr(info);
  }


  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
        
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo==="Validado"?
                `${columna.titulo} ${vali}`:columna.titulo==="Rechazado"?
                `${columna.titulo} ${recha}`:columna.titulo==="Pendiente"?
                `${columna.titulo} ${pendiente}`:columna.titulo==="OBS"?
                `${columna.titulo} ${observ}`:columna.titulo 
              } aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody> 
        {/* <TableRow className={classes.tableRow} selected={ registro?.Id === 0} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={0} onClick={ () => onChangeTable({Id:0,Num:0}) }  
        >   
         
          {celda("center", {width:"2rem"}, "GENERAL",0) }                                                                  
        </TableRow> */}
        {auxDatos.map((elemnto,index) => {  
          const auxVal=registro?.Id === elemnto.Id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index+1} onClick={ () => onChangeTable(elemnto) }  
          >   
         
            {celda("left", {width:"8rem"}, elemnto.Nom,index+1) }
            {celda("center", {width:"4rem"}, elemnto.SeccTot,index+1) }
            {celda("center", {width:"2rem"}, elemnto.TpObsvr,index+2) }
            {/* celda("center", {width:"2rem"}, elemnto.TpRg,index+3) }
            {celda("center", {width:"2rem"}, elemnto.TpRc,index+4) */ }
            {celda("center", {width:"2rem"}, elemnto.Valido,index+5) }
            {celda("center", {width:"2rem"}, elemnto.Pendiente,index+6) }
            {celda("center", {width:"2rem"}, elemnto.Rechazado,index+7) }
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
    const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
    const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}>
                                    <Typography variant="h5" component="h5">No contiene datos</Typography>
                                    </Box>)
                                                                                
                           
                                
  return (
  <Box display={'flex'} flexDirection="row" style={{height: '25.5rem',marginLeft:".5rem"}}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-150}}>                        
         
      {llamada}   
    </Card>

  </Box>                    
  );
}