import React,{useState,useEffect,useContext} from 'react';
import {Box,LinearProgress,//IconButton,Typography
    IconButton,Tooltip,FormControl,InputLabel,Select,
    MenuItem,TextField,FormControlLabel,Checkbox} from "@material-ui/core";
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import TablaDto from './TablaDto';
import Graficas from './GraficasRegion';
import CardTotal from './CardTotal';
import MapaDto from '../MapaEdo/MapaDto';
import TablaMun from './TablaMun';
import GraficaPolar from './GraficaPolar';

const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
"#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const AvanceDto = () => {
    const history = useHistory();
    const ColBase=process.env.REACT_APP_Fondo_Color
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo');          
    const usu= localStorage.getItem('UsuarioNom');   
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 ? localStorage.getItem('Dispvo') :'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
    const [listTotl, setListTotl] = useState([])                   
    const [listAv, setListAv] = useState([])                
    const [espera, setEspera] = useState(false)       
    const [listDistrito, setListDistrito] = useState([])           
    const [registro, setRegistro] = useState({Id:0,Num:0})      
    const [regMun, setRegMun] = useState(null)            
    const [listAvMun, setListAvMun] = useState([])        
    const [listGralMap, setListGralMap] = useState([])        
    const [listGralOrg, setListGralOrg] = useState([])        
    const [mapRegis, setMapRegis] = useState(null)        
    const [long,setLong] = useState(-99.6140004);
    const [lat,setLat] = useState(19.3670181);
    const [zoom, setZoom] = useState(7.3);

    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }                       
           
            guardarGenerales({...generales,Titulo:'Avance Distrito'})      
            llenaTotales(1,0)    
            llenaListDistr()
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {
            if (registro!==null &&  registro?.Id!==0) 
            {
                llenaTotales(2,registro.Id)
                const filtro =listTotl[0].avc_dto.filter((dto)=>dto.Id===registro.Id)
                setListAv(filtro)
                let editable=listGralOrg.filter(obtenPos )
                //console.log(editable);
                if (editable.length!==0) 
                {   let nvo=[]
                    listGralOrg.forEach(element => {
                        let aux=element
                        if (element.pos.length === editable[0].pos.length && element.pos === editable[0].pos) 
                        {   let nvoPos=[]
                            for (let index = 0; index < element.pos.length; index++) {
                                
                                if (element.pos[index] === `${registro.Id}`) {                                
                                    nvo.push({pos:[`${registro.Id}`],auxColor:ColBase})
                                }else{
                                    nvoPos.push(element.pos[index])
                                }
                                
                            }
                            /* if (element.pos === editable[0].pos) {
                                aux={pos:element.pos,auxColor:ColBase}
                            } */
                            //console.log(nvoPos);
                            aux={pos:nvoPos,auxColor:element.auxColor}
                           // console.log(aux);
                             
                        }
                        
                        nvo.push(aux)
                    });
                    //console.log(nvo);
                    setListGralMap(nvo)
                }
                const coord=listDistrito.filter((dist)=>dist.Id ===registro.Id)
                if (coord.length!==0) {
                    setLong(coord[0].Lon) 
                    setLat(coord[0].Lat)
                    setZoom(9.5)
                }
                //console.log(editable);
            }else{
                setListGralMap(listGralOrg)
                setListAv(listTotl[0].avc_gral)  
                setListAvMun([])
                setRegMun(null)
                setLong(-99.6140004) 
                setLat(19.3670181)
                setZoom(7.3)
            }    
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[registro]);

    useEffect(()=>{             
        if (listTotl.length!==0 ) {
            if (mapRegis!==null  ) 
            {   setRegistro({Id:parseInt(mapRegis.DTO_ID),Num:mapRegis.DTO_NOM})
               // llenaTotales(2,mapRegis.DTO_ID)
             
            } 
        }
        
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mapRegis]);

    function obtenPos(num) {
        let bandera=false
        //console.log(num);
        for (let i = 0; i<num.pos.length ; i++) {
            const pos=num.pos[i]
          //  console.log(pos);
          if (pos=== `${registro.Id}`) {
            bandera=true;
          }
        }
        return bandera;
    }

    function llenaListDistr()  {            
        const auxU= `lugar-distrito-tipo`;                            
        const dataInfo = qs.stringify({     
            usuario:auxId,
            tipo:"F",                                       
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {                                                    
                setListDistrito(aux)
                let auxNumDist = aux.length
                if (auxNumDist > arreColores.length) 
                {
                    let auxArregloColor=[], auxArregloColor2=[]
          
                    aux.forEach((element,index )=> {
                        const nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)                
                        auxArregloColor.push({pos:element.Id,auxColor:arreColores[nivel]})      
                    });
                    for (let index = 0; index < arreColores.length; index++) 
                    {
                        let auxPos=[]
                        const filtrado=auxArregloColor.filter((color)=>color.auxColor===arreColores[index] )  
                        filtrado.forEach((color,indice)=>{                        
                            auxPos[indice] = `${color.pos}`
                        })              
                        
                        /* if (index <= 2) {
                            auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})        
                        }   */
                        auxArregloColor2.push({pos:auxPos,auxColor:arreColores[index]})   
                    }
                                   
                    setListGralMap(auxArregloColor2)
                    setListGralOrg(auxArregloColor2)
                }                    
            }                                                            
        }
        llamadaApiToken(dataInfo, auxU, respuesta, ErrorGeneral, history, source);
    };

    const llenaTotales = (auxTipo,auxLlave) => {
        const data = qs.stringify({
            Usuario: auxId,           
            Tipo:auxTipo,
            Llave:auxLlave,
            idDispositivo: auxDispV,
        });
        const url = "estructura-avance-dto-1-1";

        function respuesta(response) {
            if (response[0].Id !== -1) 
            {  // console.log(response);
                if (auxTipo===1) 
                {
                    setListAv(response[0].avc_gral)   
                    setListTotl(response) 
                }else{
                    if (auxTipo===2) 
                    {
                        setListAvMun(response)
                      //  console.log(response);
                    }
                }                                           
            }
        }
    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };

  return (
    <div style={{display:"flex"}}>
        {listDistrito.length !== 0 ?
            <TablaDto listDistrito={listDistrito} registro={registro} setRegistro={setRegistro}
            />
        :null
        }
        <Box>
            {listTotl.length !== 0 ?
                <CardTotal valor={listTotl[0]} dtoId={registro?.Id} />
            :null
            }
            {listAv.length !== 0 ? 
                <Graficas valor={listAv} />
            :null }
            <Box display={"flex"} flexDirection={"row"} >
                { listGralMap.length !== 0 ?
                    <MapaDto  arregloColor={listGralMap} setMapRegis={setMapRegis} 
                        long={long} lat={lat} zoom={zoom}
                    />       
                :null}
                { listAvMun.length !== 0 ?
                    <TablaMun  listAvMun={listAvMun} registro={regMun}  setRegistro={setRegMun} />       
                :null}
                {regMun!==null  ?
                    <GraficaPolar valor={regMun} />
                :null}
            </Box>            
        </Box>
        
    </div>
  )
}

export default AvanceDto