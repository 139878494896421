import React,{useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
      height:3.5+'rem',
      width:130,
     // padding:'1.1 rem 0px 1.12rem',
      marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'.8rem',
    },
}));


export default function CardTotal({valor,munId}){
    const classes = useStyles();
    const[total,setTotal]= useState([])
    const auxColor=process.env.REACT_APP_Fondo_Color
    const nvl= localStorage.getItem('Tipo')? parseInt(localStorage.getItem('Tipo')) : 0;   

    React.useEffect(()=>{
        console.log(valor);

        let auxGrNo=valor[0].AvcGralNo//+valor.AvcUsrNo
        let auxGrRG=valor[0].AvcGralRg
        let auxGrRC=valor[0].AvcGralRc

        if (munId&& munId!=="") {
            const filtrado =valor[0].municipios.filter((mun)=>mun.Id===munId)
            auxGrNo=filtrado[0].SecAvcNo//+valor.AvcUsrNo
            auxGrRG=filtrado[0].SecAvcRg
            auxGrRC=filtrado[0].SecAvcRc            
        }
        const auxUsrNo=valor[0].AvcUsrNo//+valor.AvcUsrNo
        const auxUsrRG=valor[0].AvcUsrRg
        const auxUsrRC=valor[0].AvcUsrRc

        const auxGralMun=valor[0].AvcGralMuni//+valor.AvcUsrNo
        const auxGralSec=valor[0].AvcGralSecc      
        const auxTotMun=valor[0].totalMuni//+valor.AvcUsrNo
        const auxTotSec=valor[0].totalSecc        
        setTotal({GrRG:auxGrRG,GrRC:auxGrRC,GrNo:auxGrNo,
            UsrRG:auxUsrRG,UsrRC:auxUsrRC,UsrNo:auxUsrNo,
            gralMun:auxGralMun,gralSec:auxGralSec,
            totMun:auxTotMun,totSec:auxTotSec,
        })
    },[])  

    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }

    function generarCards2(mensaje,datGen,datTot,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datGen} displayType={'text'}  thousandSeparator={true}/>
                {' / '}
                <NumberFormat value={datTot} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
  
    return ( 
        
    <Box display="flex" flexDirection="row" mb={1}  >   
    {total.length!==0 ? 
        <Box display="flex" flexDirection="row">                                               
        { nvl === 1 || nvl === 7||  nvl === 9|| nvl === 10  ?
            <Box display="flex" flexDirection="row">  
                <Card className={classes.card}>
                    {generarCards2('Municipio',total.gralMun, total.totMun,auxColor)}
                </Card>
                <Card className={classes.card}>
                    {generarCards2('Sección',total.gralSec, total.totSec,auxColor)}
                </Card>
                <Card className={classes.card}>
                    {generarCards(munId? 'Municipio RG':'General RG',total.GrRG,'#58b85c')}
                </Card>
                <Card className={classes.card}>
                    {generarCards(munId? 'Municipio RC':'General RC',total.GrRC ,'#4295f5')}
                </Card>
                <Card className={classes.card}>
                    {generarCards(munId? 'Municipio OBS':'General OBS', total.GrNo,auxColor)}
                </Card>
            </Box>
            :null     }
            <Box display="flex" flexDirection="row">
                <Card className={classes.card}>
                    {generarCards('Usuario RG',total.UsrRG,'#58b85c')}
                </Card>
                <Card className={classes.card}>
                    {generarCards('Usuario RC',total.UsrRC ,'#4295f5')}
                </Card>
                <Card className={classes.card}>
                    {generarCards('Usuario OBS', total.UsrNo,auxColor)}
                </Card>                 
            </Box>
        </Box>   
        :null}
    </Box>
    );
}
 