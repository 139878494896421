import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Polygon, Marker } from '@react-google-maps/api';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
  let arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

export default function MapaMarcadorSeccion({poligonos,centro ,cambiaMarcador,setCambiaMarcador,redimensionar,setCoordenada,listPoligono,listMapProm,listApoyos,listRecorrido,listObserv}){
  
  let altuTamMax=825
  let anchoTamMax=1535
  const [rutaPol, setRutaPol] = useState([]);
  const [seccPolEntrada, setSeccPolEntrada] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);    
  const [auxColores, setAuxColores] = useState(arreColores);
  const [alturaContenedor, setAlturaContenedor] = useState (
                                            (!redimensionar) ? (window.innerHeight<altuTamMax ? window.innerHeight-165: window.innerHeight - 200)
                                                             : (window.innerHeight<altuTamMax ? window.innerHeight-215: window.innerHeight - 250))
  const [anchoContenedor, setAnchoContenedor] = useState(
                                            (!redimensionar) ? window.innerWidth<anchoTamMax ? window.innerWidth-200 : window.innerWidth-300
                                                             : window.innerWidth<anchoTamMax ? window.innerWidth-510 : window.innerWidth-750)
  const [auxMarcador, setAuxMarcador] = useState([]);
  const [auxPoligRevision, setAuxPoligRevision] = useState([]);
  useEffect(()=>
  {// console.log(listObserv);
    let seccionEntrada=[]    
    if (listPoligono.length>0) 
    {  
      try {
        let poligonoRevision=JSON.parse(listPoligono);  
        setAuxPoligRevision(poligonoRevision)       
        poligonoRevision.forEach((poligono) => {                  
          let auxCoord=generaCoordenadaDinam(poligono[1], poligono[0]) 
          seccionEntrada.push(auxCoord)                            
        })  
        setSeccPolEntrada(seccionEntrada);      
      } 
      catch (error) {        
        let mensaje2="Error en la cadena "+error                                         
        toast.error(mensaje2, 
        {   position: toast.POSITION.BOTTOM_RIGHT, 
            theme: "colored", autoClose: 10000, 
            containerId:'mapaSeccionPol'
        });
      }
      
    }
    else
    { 
      setSeccPolEntrada([])
      setAuxPoligRevision([])
    }

    if (poligonos)
    {      
      let ordenado=[]
      poligonos.forEach((poligono) => {
        let arr=[]
        poligono.gps.forEach((coorde)=>{
          let auxCoord=generaCoordenadaDinam(coorde.Lat, coorde.Lon) 
          arr.push(auxCoord)                 
        })
        ordenado.push({Id:poligono.Id,gps:arr,Color:poligono.Color?poligono.Color:""})      
      })
 
      setRutaPol(ordenado);

      let auxNumSecc = poligonos.length
      if (auxNumSecc > arreColores.length) 
      { let auxArregloColor=[] 
        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        setAuxColores(auxArregloColor)
      }          
    }    
    setCambiaMarcador(true)      

  },[centro,poligonos,listPoligono]);


useEffect(()=>{
  window.addEventListener('resize', function() {
    if(!redimensionar){
        if(window.innerHeight<altuTamMax){
          setAlturaContenedor(window.innerHeight-165)
          }else{
              setAlturaContenedor(window.innerHeight-200)
          }
          if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-200)
          }else{
            setAnchoContenedor(window.innerWidth-200)
          }
    }else{
      if(window.innerHeight<altuTamMax){
            setAlturaContenedor(window.innerHeight-200)
        }else{
            setAlturaContenedor(window.innerHeight-300)
        }

        if(window.innerWidth<anchoTamMax){
            setAnchoContenedor(window.innerWidth-510)
        }else{
            setAnchoContenedor(window.innerWidth-750)
        }
    }
});
},[alturaContenedor,anchoContenedor,anchoTamMax,altuTamMax,redimensionar])

  const onLoad =(respuesta)=> {
    setAutocomplete(respuesta);

  }

 
  const options =(Color,numero)=> {    
    const auxiliar={  
    fillColor:  Color.length!==0?Color: auxColores[numero],
    fillOpacity: 0.3,
    strokeColor: "orange",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const options2 =(numero)=> {    
    const auxiliar={  
    fillColor: auxColores[numero],
    fillOpacity: 0.3,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }
  const image = { url:"marcador.svg" };
  const image2 = { url:"marcador_seleccionado.svg" };
  const image3 = { url:"marcador_poligono.svg" };

  const seleccionCordenada=(e)=>{
    //e.preventDefault()
    
    console.log(`location[${JSON.stringify(e.latLng)}]`);    
    let aux1=parseFloat(e.latLng.lat())
    let aux2=parseFloat(e.latLng.lng())              
    let arreMarcador= { lat : aux1, lng :aux2 }
    console.log(arreMarcador);
    setAuxMarcador(arreMarcador)
  }

  const posicionMarcador=(e,poligono)=>{
    //e.preventDefault()    
    setCoordenada(poligono)
  }

  return (    
  <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
    <GoogleMap
      id="searchbox-example"  zoom={15.5} center={centro}
      mapContainerStyle={{   width:anchoContenedor, height:alturaContenedor }}           
    >  
    { cambiaMarcador && auxPoligRevision && auxPoligRevision.length>0 ?
      auxPoligRevision.map((marcador,index) => 
      {
        if((marcador[0] !== undefined ||  marcador[0] !== null || marcador[0] !== "")
          && (marcador[1] !== undefined ||  marcador[1] !== null || marcador[1] !== "")
        ) 
        {          
          let auxCoord=generaCoordenadaDinam(marcador[1], marcador[0])                   
          return(
          <Marker
            onLoad={onLoad} icon= {image3}
            title={marcador.promNombre}
            position={ auxCoord}                                          
          />
          )
          
        }        
      })      
      :null 
    }  
    
    { seccPolEntrada !== null && seccPolEntrada.length > 0 ?  //lista de poligonos             
        <Polygon
          onLoad={onLoadPolygono} paths={[seccPolEntrada]}
          options={options2(5)}
        />
              
      :null
    }      
    { rutaPol!==null?  //lista de poligonos
      rutaPol.map((poligono,index) => 
      { 
        return(
        <Polygon
          onLoad={onLoadPolygono} paths={poligono.gps}
          options={options(poligono.Color, index)}
        />
        )
      })      
      :null
    }      
    { poligonos!==null && poligonos.length > 0 ? //marcadores graficados de la api
      poligonos.map((poligono,index) => 
      { 
        return(
        <Marker
          icon= {image} onClick={e=>posicionMarcador(e,poligono)}
          position={{lat :parseFloat(poligono.seccionLat) ,
            lng:parseFloat(poligono.seccionLon)}}
          //options={options(index)}          
        />
        )
      })      
      :null
    }       
    {listMapProm && listMapProm.length!==0 ?
        listMapProm.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon) 
          //console.log(auxCoord);
          const aviso=perfil.Gps===0?"\nSin Coordenadas":""
          const icono=perfil.Gps=== 1 ? (perfil.StatusId===2 ? "marcador-valido.svg":(perfil.StatusId===1? "marcador-pendiente.svg":"marcador-rechazado.svg"))
          :( perfil.StatusId===2 ? "marcador-valid-snc.svg":(perfil.StatusId===1? "marcador-pend-snc.svg":"marcador-rech-snc.svg") )
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Nom} \n${perfil.Tel} ${aviso}`}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
    :null}
    {listApoyos && listApoyos.length!==0 ?
        listApoyos.map((perfil,index)=>{
          const auxCoord=generaCoordenadaDinam(perfil.Lat, perfil.Lon)                     
          const icono="marcador-valido.svg"       
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${perfil.Nom} \n${perfil.Tel} `}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listRecorrido && listRecorrido.length!==0 ?
        listRecorrido.map((recorr,index)=>{
          const auxCoord=generaCoordenadaDinam(recorr.Lat, recorr.Lon)                     
          const icono="marca_recorrido.svg"       
          return(
            <Marker style={{width:50+'px',height:50+'px'}}
              title={`${recorr.TipoNom} \n${recorr.Obsrv} `}   key={index}
              position={ auxCoord}  
              icon= {icono}                   
            />)
        })
      :null}
      {listObserv && listObserv.length!==0 ?
        listObserv.map((recorr,index)=>{
          const auxCoord=generaCoordenadaDinam(recorr.Lat, recorr.Lon)                     
          const icono="marcador_observ.svg"       
          return(
          <Marker style={{width:50+'px',height:50+'px'}}
            title={`${recorr.TipoNom} \n${recorr.Obsrv} \n${recorr.Dir}`}   key={index}
            position={ auxCoord}  
            icon= {icono}                   
          />)
        })
      :null}
  </GoogleMap>
</LoadScript>  
);
} 