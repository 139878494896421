import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,Box, Typography,
        TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaginacionTab from '../layout/PaginacionTab'
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '25rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'1rem',        
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '23rem',
      minHeight: (window.innerHeight-190)/4,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '23rem',
        maxHeight: (window.innerHeight-240)/4,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[              
        
    {
      id:1, titulo:"Avance", alinear:'center'
    }, 
    {
      id:2, titulo:"Titular", alinear:'left'
    }, 

  ];
const alturaCont = window.innerHeight<937 ? (window.innerHeight-180)/4 : (window.innerHeight-200)/4

export default function TablaRegional({muni,getDatos,seleccion, setSeleccion}){    
  const classes = useStyles();    
  const limiteRow=150
  const auxCol=process.env.REACT_APP_Fondo_Color
  const [promotor, setPromotor] = useState({nombre:""})  
  const [datos,setDatos] = useState([]);     
  const [totalPaginas,setTotalPaginas]=useState(1);
  const [numPagina,setNumPagina]=useState(0);    
  const {nombre} = promotor;    

  useEffect(()=>{
    if(muni.length>limiteRow)
    { let auxTam = muni.length;
      if(auxTam%limiteRow===0)
      {
        let pagina=Math.trunc(auxTam/limiteRow)
        setTotalPaginas(pagina)   
      }
      else
      { let pagina=Math.trunc(auxTam/limiteRow)
        pagina=pagina+1;
        setTotalPaginas(pagina)
      }
      setNumPagina(0) 
      setDatos(muni.slice(0,limiteRow))
    }
    else
    { setTotalPaginas(1)
      setNumPagina(0)
      setDatos(muni)
    }
    
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[muni])          

  const onChangeTable =(regis)=>{                       
    setSeleccion(regis)
    getDatos(0,regis.SupId)
  }

  function filtro(){        
    let auxCadena=nombre.trim();       
    let info=[];                        
    let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
    info=aux 
    let auxNum=0;
      if (aux!==null||aux!=='') 
      {
      aux.forEach((mun)=>
      { if (mun.nombreReg!=='EDOMEX')
        {   
          auxNum++;
        }                
      })
    }         
    return TablaEstr(info);
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
  
  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>                       
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>                                  
        {auxDatos.map((elemnto,index) => {  
          const auxVal=seleccion?.SupId === elemnto.SupId ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index} onClick={ () => onChangeTable(elemnto) }  
          >   
            {celda("right", {width:"50px",paddingRight:".3rem"}, elemnto.Avance,index+8) }                                                        
            {celda("left", {width:"10rem"}, elemnto.SupNom,index+8) }          
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
  
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {   setDatos(muni.slice(0,limiteRow))   
    }
    else
    {   setDatos(muni.slice(newPage * limiteRow, newPage * limiteRow + limiteRow))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false}
      style={{padding:0}} rowsPerPage={limiteRow}
      count={muni.length} page={numPagina}
      lang='es'  onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}
      labelDisplayedRows={
          ({ from, to, count }) => {
          return '' + from + '-' + to + ' de ' + count
          }
      }
    />)
  }    

  const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
  const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}><Typography variant="h4" component="h3">No contiene datos</Typography></Box>)                        
  const paginacion = totalPaginas>1 ? generarFooter() : null;        

  return (
  <Box display={'flex'} flexDirection="column" style={{height: window.innerHeight-550}}>
    <Box  display={'flex'} mb={1}>      
      {paginacion}   
    </Box>
    
    <Card className={classes.estiloCard} style={{height:(window.innerHeight-550)}}>                              
      {llamada}   
    </Card>
  </Box>                    
  );
}