import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Collapse,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import ModalNvoEdit from './ModalNvoEdit';
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaOrganizacion({   history, selectedID, setSelectedID,listMun,
                                            source, idusu, buscar, checked, setRecorrido,
                                            actualiza,cadena,listDistrito, listTipo
                                        }) {
    const classes = useStyles();
    let filter = 0    
    const usuTipo = localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):null;
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    const [modalNvoEdit, setModalNvoEdit] = useState(false);
    
    useEffect(() => {
       
        llenarTabla();
    }, [buscar]);    

    const llenarTabla = () => {
        setEspera(true)
        if (checked) {
            filter = idusu
        } else {
            filter = 0
        }

        const data = qs.stringify({
            Usuario: idusu,
            Filtro: usuTipo=== 1 ? 0:1,
            Cadena:cadena,
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = "organizacion-list";

        function response(data) {
            if (data[0].Id != -1) {
                guardarSinDatos(true)
                guardarDatos(data)
                setEspera(false)
            } else {
                guardarSinDatos(false)
                setEspera(false)
            }
        }

        llamadaApiToken(data, url, response, ErrorGeneral, history, source);
    };
    
    const seleccionar = ( auxOrden) => {
        setSelectedID(auxOrden)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }

    const celda = (alinear, estilo, contenido, index) => {
        return (<TableCell align={alinear} className={classes.tableCell} 
            padding={'none'} style={estilo} key={index}
        >
            {contenido}
        </TableCell>)
    }

    
    const celdaEncabezado = (alinear, contenido,index) => {
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} key={index}
            style={{backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white"}}>
            {contenido}
        </TableCell>)
    }

    function Row(props) {
        const {row} = props;
        const icono=selectedID?.Id === row.Id ?
            <CreateIcon style={{color: 'white'}}/> 
            : <CreateIcon/>
        return (      
        <TableRow onClick={() => { seleccionar(row) }}
            className={classes.tableRow} selected={selectedID?.Id === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >   
            {celda("center", {fontSize: '12px',width:40}, row.Id, 10) }                                                        
            <TableCell align="center" padding={'none'} style={{width: 30}}>
                <Tooltip title={'Editar Organización'}>
                    <IconButton color="primary" size="small"
                        onClick={() =>  setModalNvoEdit(true)}>
                        {icono}
                    </IconButton>
                </Tooltip>
            </TableCell> 
            {celda("center", {fontSize: '12px',width:90}, row.TpNom, 11) }   
            {celda("left", {fontSize: '12px',width:150}, row.Alias, 6) }            
            {celda("left", {fontSize: '12px',width:150}, row.JefeNom, 2) }          
            {celda("center", {fontSize: '12px',width:90}, row.Tel, 5) }     
            {celda("center", {fontSize: '11px',width:90}, row.Ine, 9) }    
               
            {celda("center", {fontSize: '12px',width:60}, row.DtoNom, 10) }      
            {celda("left", {fontSize: '12px',width:150}, row.MuniNom, 7) }                
            {celda("center", {fontSize: '12px',width:55}, row.SeccNom, 8) }                    
            {celda("left", {fontSize: '12px',width:180}, row.Responsable, 4) }                                                                
            {celda("left", {fontSize: '12px',width:150}, row.Email, 3) }                                                          
        </TableRow>
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>                                                
                        {celdaEncabezado("center","",10)}
                        
                        {celdaEncabezado("center", auxlista.length,9)}
                        {celdaEncabezado("center", "Tipo",11)}
                        {celdaEncabezado("center", "Alias",1)}
                        {celdaEncabezado("center", "Jefe",2)}
                        {celdaEncabezado("center", "Teléfono",3)}
                        {celdaEncabezado("center", "Clave de Elector",4)}                        
                        {celdaEncabezado("center", "Distrito",10)}
                        {celdaEncabezado("center", "Municipio",5)}
                        {celdaEncabezado("center", "Sección",6)}
                        {celdaEncabezado("center", "Responsable",7)}
                        {celdaEncabezado("center", "Email",8)}                                                
                    </TableRow>
                </TableHead>
                <TableBody>
                    {auxlista.length != 0 ? (auxlista.map((row) => (
                        <Row key={row.Id} row={row}/>
                    ))) : null}
                    
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {celdaEncabezado("center","",9)}
                        {celdaEncabezado("center", "Tipo",11)}
                        {celdaEncabezado("center", "Alias",1)}
                        {celdaEncabezado("center", "Jefe",2)}
                        {celdaEncabezado("center", "Teléfono",3)}
                        {celdaEncabezado("center", "Ine",4)}                        
                        {celdaEncabezado("center", "Distrito",10)}
                        {celdaEncabezado("center", "Municipio",5)}
                        {celdaEncabezado("center", "Sección",6)}
                        {celdaEncabezado("center", "Responsable",7)}
                        {celdaEncabezado("center", "Email",8)} 
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                        <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                        <TableCell className={classes.tableCell} align="left">Recorridos</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)
    const modNvo =modalNvoEdit ? <ModalNvoEdit abrirModal={modalNvoEdit} setAbrirModal={setModalNvoEdit} 
                                    registro={selectedID} listMun={listMun} actualiza={actualiza}
                                    titulo={`Editar Organización ${selectedID.Id}`} listDistrito={listDistrito}   
                                    listTipo={listTipo}
                                />:null
    return (
    <Paper>
        {tabla}
        {modNvo}
    </Paper>
    );
}