import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:18+'rem'
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function GraficaEncargado({valor}){
  const classes = useStyles();
  let color1= '#19A11E';  //ff1744  
  let color2= '#FBBE06';
  let color3='#E91E63'   
  let color4='#FB7106' 
  const [totalResta,setTotalResta] = useState(0)
  const [valorLocal,setValorLocal] = useState([])
  const [miData,setMiData] = useState([])
  const [titulo,setTitulo] = useState([])
  useEffect(()=>{
    if (valor !==0) 
    { 
      console.log(valor);
      let auxRes = valor.Avance- (valor.Pendiente+valor.Validado)
      setTotalResta(auxRes)   
      setValorLocal(valor)    
      let arr=[], titulos=[]
      valor.forEach((titular)=>
      { titulos.push(`${titular.Dia}/0${titular.Mes}`)
        arr.push( titular.Avance)
      })
      const nvo=[{type: 'line',label:valor[0].UsrNom, data:arr, 
      backgroundColor:color4,   fill: false,tension: 0.4,borderColor:color2}]
      setMiData(nvo)
      setTitulo(titulos)
    } 
  },[valor])


  const dataSinPerfil = {
    //type: 'line',
    labels: titulo,
    datasets:miData     
  }; 

  const options = {
    plugins: {
      legend: {
          display: true
      }
    },
    //indexAxis:'y',
    tooltips: {
      enabled: true,
    },
    layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    },
    title:{
      fontStyle: 'bold',
    },
   /*  scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        //stacked: true,
        label :{
          fontSize:6,
        },
      }
    
    }, */
  } 

  function  llenadoTabla(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' ,paddingBottom:.5+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={300} height={75} 
        options={options}
      />   
    </Card>
    );
  }
  function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
    ,paddingBottom:.5+'rem',height:18+'rem',width:28.5+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={300} height={200} 
        options={options}
      />   
    </Card>
    );
  }
  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} pt={1} style={{height:18+'rem'}}>      
    { valor.Validado > 0 || valor.Pendiente > 0 || valor.Rechazado > 0 ?
      <Box display="flex"className={classes.contenedorCard}  >
        {llenadoTabla2(dataSinPerfil,'Perfiles')}
      </Box>
    : <Box > 
        {/* <Box display="flex"className={classes.contenedorCard} >
          {llenadoTabla(dataSinPerfil,'Perfiles')}
        </Box> */}
         <Box display="flex"className={classes.contenedorCard}  >
        {llenadoTabla2(dataSinPerfil,'Perfiles')}
      </Box>
      </Box>  
    }
  </Box>
  );
}