import React,{useState,useEffect} from 'react';
import {Box, IconButton, LinearProgress, Tooltip,} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import TablaEstruct from './TablaEstructura';
import {llamaApiCargaToken,llamadaApiToken,ErrorGeneral } from '../funciones/ClienteAxios';
import GraficaAvTitular from './GraficaAvTitu';
import TablaEncargado from './TablaEncargado';
import GraficaEncargado from './GraficaEncargado';
export default function AvanceTitular(){ 
    const history = useHistory();    
    const source = axios.CancelToken.source();
    const auxId =localStorage.getItem('UsuId') ;  
    const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;      
    const Dispvo =localStorage.getItem('Dispvo') ; 
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'      
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);                 
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
    const opera = localStorage.getItem("Operacion")+"|";
    const[espera,setEspera]=useState(false);
    const[espera2,setEspera2]=useState(false);
    const[esperaEncarg,setEsperaEncarg]=useState(false);
    const[listAfil,setListAfil]=useState([])
    const[listEncargado,setListEncargado]=useState([])
    const[listDet,setListDet]=useState([])
    const [seleccion, setSeleccion] = useState(null)
    const [encargado, setEncargado] = useState(null)
    const {inicio,final}=fSelect  
    //const drawerWidth = 125;
    useEffect(()=>{             
        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            history.push("/");
            guardarUsuario([])
        }
        else
        {   const usu= localStorage.getItem('UsuarioNom');                 
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                                           
            }      
   
            guardarGenerales({...generales,Titulo:'Avance General'})      
           
        }
        
        return ()=> {
            source.cancel();
        }      
    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getDatos = (auxTit, auxLlave) => {   
        const auxF1 =moment(fSelect.inicio).format('YYYY-MM-DD')+"T00:00:00"           
        const auxF2 =moment(fSelect.final).format('YYYY-MM-DD')+"T23:59:59"           
        const data = qs.stringify({
            Usuario: auxId,                     
            Titular:auxTit,
            FIni:auxF1,
            FFin:auxF2,
            Llave:auxLlave,            
            idDispositivo: auxDispV,
        });
        const url = "estructura-avance-titular";
        function respuesta(aux) {
            if (auxTit===1) 
            {   if (aux[0].SupId!== -1 ) 
                {   setListAfil(aux)                                       
                }
                else
                {   setListAfil([])
                    setListEncargado([])
                    setSeleccion(null)
                }                
            }
            else
            {   if (aux[0].SupId!== -1 ) 
                {   setListEncargado(aux)
                }
                else
                {   setListEncargado([])                                        
                }
            }                
            setEncargado(null)
            setListDet([])   
            
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,auxTit===1?setEspera:setEspera2,history,source);
    };

    const getListDetalle = (auxTit, auxLlave) => {   
        const auxF1 =moment(fSelect.inicio).format('YYYY-MM-DD')+"T00:00:00"           
        const auxF2 =moment(fSelect.final).format('YYYY-MM-DD')+"T23:59:59"           
        const data = qs.stringify({
            Usuario: auxId,                     
            Titular:auxTit,
            FIni:auxF1,
            FFin:auxF2,
            Llave:auxLlave,            
            idDispositivo: auxDispV,
        });
        const url = "estructura-avance-titular-det";
        function respuesta(aux) {
            if (aux[0].SupId!== -1 )
            {   setListDet(aux)                                             
            }
            else{
                setListDet([])         
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEsperaEncarg,history,source);
    };

    const guardarInicio = auxfecha =>
    {
        setFSelect({...fSelect, inicio : auxfecha })
    };

    const guardarFinal = auxfecha =>
    {
        setFSelect({ ...fSelect, final : auxfecha })
    };

    const Reloj =()=>{
    return<MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>    
        <Box component="span"  >
        <Box  ml={1}  component="span" width={1/4}>                                                
            <DatePicker
                disableFuture format="dd/MM/yyyy"
                views={["year", "month", "date"]}
                label="Fecha Inicial" size="small"
                minDate="2021-12-31" maxDate={new Date()}
                value={inicio} onChange={guardarInicio}      
                style={{width:6.5+'rem'}} cancelLabel={"Cancelar"}                  
                okLabel="Seleccionar" 
            />
        </Box>    
        <Box ml={1}  component="span" width="25%">
            <DatePicker
                disableFuture id="final" 
                format="dd/MM/yyyy" label="Fecha Final"
                views={["year", "month", "date"]}
                minDate={inicio} maxDate={new Date()}
                value={final} name="final" cancelLabel={"Cancelar"}  
                size="small" style={{width:6.5+'rem'}}      
                okLabel="Seleccionar"  onChange={guardarFinal}
                minDateMessage={"La fecha final debe ser menor a Fecha inicial"}
            />
        </Box> 
    </Box>                         
    </MuiPickersUtilsProvider>
    }

    const contenido=()=>{
        return(
        <Box display={'flex'} flexDirection="column" >         
            <Box mb={1}>                      
                <IconButton color="primary"  aria-label="add circle" 
                    component="span" onClick={()=>getDatos(1,0)}>
                    <Tooltip title="Buscar">
                        <SearchIcon/>
                    </Tooltip>
                </IconButton>                              
                {Reloj()}                                             
            </Box>                      
            <Box display={"flex"} mb={1}>
                <TablaEstruct muni={listAfil} getDatos={getDatos} 
                    seleccion={seleccion} setSeleccion={setSeleccion}
                />
                {listAfil.length!==0?<GraficaAvTitular valor={listAfil} />:null}
            </Box>   
            {espera2 ?
                <Box width={"22rem"}  >
                    <LinearProgress/>
                </Box> 
            :   (listEncargado.length!==0?
                    <Box display={"flex"}  >
                        <TablaEncargado muni={listEncargado} getDatos={getListDetalle} 
                            seleccion={encargado} setSeleccion={setEncargado}
                        />
                        {listDet.length!==0?
                            !esperaEncarg?<GraficaEncargado valor={listDet} />
                            :   <Box width={"22rem"}  > <LinearProgress/> </Box> 
                        :null}
                    </Box>
                :   null  )
            }         
        </Box>)
    }

    return (
    <Box display="flex" flexDirection="row" style={{ marginLeft:'1rem'}} >        
        {   !espera?
            (contenido)        
            :<Box width={"22rem"} mt={3}>
                <LinearProgress/>
            </Box>
        }              
    </Box>
    )
}
 