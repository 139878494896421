import React,{useState,useEffect} from 'react'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Box, Paper,Card,FormControlLabel,Checkbox} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';        
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },     
    estiloCard:{
        width: '19.5rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
        color:'#4a4a4a',
        display:'block',
        
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },        
    tablaContainer: {
        width: '19.5rem',
        //maxHeight: window.innerHeight-170,
        minHeight: window.innerHeight-170,
        //minWidth:'100%',
        }, 
    tableRow: {
        "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
        }
    },
    tableCell: {
        "$selected &": {
        color: "white"
        }
    },
    hover: {},
    selected: {}        
});
const TablaObserv = ({listObserv,estadoId,munId,auxSec}) => {
    const classes = useStyles();   
    const history = useHistory();     
    const source = axios.CancelToken.source();
    const [idSelec,setIdSelec] = useState(null)
    const [pendiente, setPendiente] = useState(false);
    const [listDatos, setListDatos] = useState([]);
    const usuid =localStorage.getItem('UsuId') ; 
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";       
    const [espera,setEspera] = useState(true)
    useEffect(()=>{
        if (pendiente) 
        {
            llamaList()
        }
        else
        {
            setListDatos(listObserv)
        }
       
    },[pendiente])

    const llamaList = () => {        
        const data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:auxSec, idDispositivo: auxDispV,
        });
        const url = "estructura-mapa-avance-pendiente";

        function respuesta(auxiliar) {
            console.log(auxiliar);
            if (auxiliar[0].Secc != -1) {
                return setListDatos(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const RenglonNum =(valorId,regis)=>{        
        //console.log(regis);     
        setIdSelec(valorId);          
    }

    const CeldaTitulo=(contenido,alinear,estilos)=>{
        return(
        <TableCell padding='none' align={alinear} style={estilos} >
            {contenido} 
        </TableCell> 
        )
    }

    const CeldaContenido=(contenido,alinear,estilos)=>{
        return(
        <TableCell className={classes.tableCell} padding='none' align={alinear} 
            style={estilos} size="small"
        >
            {contenido} 
        </TableCell> 
        )
    }

    function TablaMunicipios(auxDatos,total) {        
        const estiloGeneral={backgroundColor:process.env.REACT_APP_Fondo_Color,color:'white'
                            , borderBottom:"1px solid #2A3345"}                
        return(        
        <TableContainer className={classes.tablaContainer} id="tamTablaMunicipios" 
            style={{height:8+'rem'}}
        >
        <Table stickyHeader aria-label="sticky table">
            <TableHead >    
                {pendiente ?
                    <TableRow> 
                        {CeldaTitulo("Sección","center",estiloGeneral)}                                                        
                    </TableRow> 
                :   <TableRow> 
                        {CeldaTitulo("Sección","center",estiloGeneral)}                 
                        {CeldaTitulo("Teléfono","center",estiloGeneral)}                                        
                        {CeldaTitulo("Nombre","center",estiloGeneral)}                          
                    </TableRow>   
                }                 
            </TableHead>  
            <TableBody>                                  
            {auxDatos.map((municipio,index) => {                                                    
                return (                  
                <TableRow className={classes.tableRow} selected={ pendiente ? idSelec?.Secc === municipio.Secc :idSelec?.Id === municipio.Id }  
                    classes={{ hover: classes.hover, selected: classes.selected }} 
                    key={index} onClick={ () => RenglonNum(municipio) }  
                >   
                    {CeldaContenido(municipio.Secc, "center", {width:"2rem"})}                                               
                    {pendiente?null: CeldaContenido(municipio.Dir, "center", {paddingLeft:0.2+'rem',width:"6rem"})}                                               
                    {pendiente?null: CeldaContenido(  municipio.Obsrv, "left", pendiente?{}:{fontSize:"12px"})}                                                                                                                                                                                           
                    
                </TableRow>              
                );                        
            })}                                                
            </TableBody>
        </Table>
        </TableContainer>  
        ); 
    }
    const onChangeObserv = () => {
        setPendiente(!pendiente)
    };

    const tabla = listDatos.length !== 0 ? TablaMunicipios(listDatos,listDatos.length):null
    return (
    <Box pl={2}>
        <FormControlLabel style={{width:"10rem"}}
            label="Pendientes" size={'small'}
            control={<Checkbox color="primary" checked={pendiente} name={"pendiente"}/>}
            labelPlacement="end" onChange={onChangeObserv} 
        /> 
        <Card className={classes.estiloCard} >
            <Paper className={classes.table}>
                {tabla}
            </Paper>
       </Card>
    </Box>
    )
}

export default TablaObserv