import React , {useState,useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Card, Box, Typography} from '@material-ui/core'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral,llamaApiFormDat2 } from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display:'flex',
    flexDirection:'row'
  },

  formSelect: {
    width:'12em'
  },

  formDesc:{
      marginTop:'1.7em',
      width:'30em',
      height:'4em',
      display:'flex',
  },
  formImg:{
    marginTop:'.5em',
    width: 18+'rem',
    height:25+'rem',
    borderRadius: '.6rem'
  },
  cardDatos:{
    width:'50%',
    height:30.5+'rem',
},
}));

  export default function EditarImagen({modalAbierto,setModalAbierto,seleccionado,titulo,setActualizar,getDatos}){
    const classes = useStyles();
    const history = useHistory();
    //const auxBase="./partidos/partido_mor.jpg"
    const auxBase="mex_corazon_logo.jpeg"
    const source = axios.CancelToken.source(); 
    const auxId =localStorage.getItem('UsuId') ;  
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [botonesDeshab,setBotonesDeshab] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [imgPrimaria, setImgPrimaria] = useState(auxBase);
    const [imgSecundaria,setImgSecundaria] = useState(auxBase)
    const [imgAcreditacion,setImgAcreditacion] = useState(auxBase)
    const [portadaEnviar, setPortadaEnviar] = useState();
    const [contraEnviar,setContraEnviar] = useState()
    const [acreditEnviar,setAcreditEnviar] = useState()
    const [reiniciar,setReiniciar] = useState(false)
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    useEffect(()=>{
      
      llamadaListFoto(seleccionado.Id)
      return ()=> {
        source.cancel();
        }
    },[seleccionado])

    function llamadaListFoto(auxLlave)  {            
        const auxU= `estructura-evidencia-list`;                            
        const dataInfo = qs.stringify({                
            Usuario:auxId,             
            Llave:auxLlave,             
            idDispositivo:auxDispV,
        });
                 
        function respuesta (aux) 
        { if (aux[0].Id !== -1) 
          {                                                    
            console.log(aux);
            const filtro1=aux.filter((foto)=>foto.Tipo==="Nombramiento")
            const filtro2=aux.filter((foto)=>foto.Tipo==="Nombramiento2")
            const filtro3=aux.filter((foto)=>foto.Tipo==="Acreditación")            
            console.log(filtro1,filtro2,filtro3);
            if(filtro1.length!==0)
            {
              setImgPrimaria(filtro1[0].Dir+filtro1[0].Archivo)
            }
              
            if(filtro2.length!==0)
            {
              setImgSecundaria(filtro2[0].Dir+filtro2[0].Archivo)
            }
            if(filtro3.length!==0)
            {
              setImgAcreditacion(filtro3[0].Dir+filtro3[0].Archivo)
            }
          }                                                           
        }
        llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
    }; 
    
    const onChangeImgPrimaria = (e) => {
        const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/;      
        let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");      
        if ( e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
        e.target.files[0].size < 10 * 1024 * 1024
        ) {
        let auxCode = uuidv4();
        //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)
        let arch = e.target.files[0];
        let auxNombre = auxCode + arch.name.slice(arch.name.lastIndexOf("."), arch.name.length);
        let auxNombre2 = auxNombre.replace(regex2, ""); //'probandoNuevo.pdf'        
        let myNewFile = new File([arch], auxNombre2, { type: arch.type });      
        setImgPrimaria(URL.createObjectURL(e.target.files[0]));
        setPortadaEnviar(myNewFile);
        setBtnDeshabilitado(false);
        } else {
        let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
        if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
            mensaje = "El tamaño del archivo debe ser menor a 10mb.";
        }
        toast.warning(mensaje, {
            pauseOnFocusLoss: false,
            theme: "colored", toastId: `incorrecto-selPrim`,
        });
        setImgPrimaria(auxBase);
        setPortadaEnviar();
        }
    };

    const onChangeImgSecundaria = (e) =>{
        const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/
        if(e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
        e.target.files[0].size < 10 * 1024 * 1024
        ){
        let auxCode=uuidv4()       
        //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
        let arch = e.target.files[0]
        let auxNombre=auxCode+arch.name.slice(arch.name.lastIndexOf("."),arch.name.length)
        let myNewFile = new File([arch], auxNombre, {type: arch.type});      
        setImgSecundaria(URL.createObjectURL(e.target.files[0]))
        setContraEnviar(myNewFile)
        setBtnDeshabilitado(false)
        }
        else
        { let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
        if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
            mensaje = "El tamaño del archivo debe ser menor a 10mb.";
        }
        toast.warning(mensaje,{
            pauseOnFocusLoss: false,
            theme: "colored", toastId: `incorrecto-selSec`
        }); 
        setImgSecundaria(auxBase )
        setContraEnviar()
        }
    }

    const onChangeImgAcreditacion = (e) =>{
      const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/
      if(e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
      e.target.files[0].size < 10 * 1024 * 1024
      ){
      let auxCode=uuidv4()       
      //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
      let arch = e.target.files[0]
      let auxNombre=auxCode+arch.name.slice(arch.name.lastIndexOf("."),arch.name.length)
      let myNewFile = new File([arch], auxNombre, {type: arch.type});      
      setImgAcreditacion(URL.createObjectURL(e.target.files[0]))
      setAcreditEnviar(myNewFile)
      setBtnDeshabilitado(false)
      }
      else
      { let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
      if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
          mensaje = "El tamaño del archivo debe ser menor a 10mb.";
      }
      toast.warning(mensaje,{
          pauseOnFocusLoss: false,
          theme: "colored", toastId: `incorrecto-selSec`
      }); 
      setImgAcreditacion(auxBase )
      setAcreditEnviar()
      }
  }

    const subirImagen = (auximg,auxtipo) => {  
        const formData = new FormData();      
        setBtnDeshabilitado(true)
        setBotonesDeshab(true)        
        formData.append('Usuario', auxId)
        formData.append('Llave', seleccionado.Id)
        formData.append('Descr', auxtipo)                        
        formData.append('idDispositivo', '45vf2d1vdf21d2f')
        formData.append('multi-files',auximg)        

        const url = 'estructura-evidencia-new';

        function respuesta(aux) {
          if(aux.success){
            let mensaje = " Subida exitosamente";
            setTipoAdvertencia("success");
            setMensaje(mensaje);
            setAviso(true);   
            if(auxtipo===1){
            setPortadaEnviar()
            }else{
            setContraEnviar()
            }
            setModalAbierto(false)
            toast.success(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `exito-env${auxtipo}`
            })
            getDatos()
          
          }
        }            
      llamaApiFormDat2(formData, url, respuesta,ErrorGeneral,setEspera,history,source,auximg.size,
        process.env.REACT_APP_EDO,"ESTRUCTURA",setProgresoEnvio)     
    }

    function verificarImg (img){
        let validado=false;
        if(img && img.size){
            validado = true;
        }else{
            validado = false;
        }
        return validado;
    }


    function handleSubmission (){
      if(verificarImg(portadaEnviar)||verificarImg(contraEnviar)|| verificarImg(acreditEnviar)){
        if(portadaEnviar){
           subirImagen(portadaEnviar,"Nombramiento");
        }
        if(contraEnviar){
           subirImagen(contraEnviar,"Nombramiento2")
        }
        if(acreditEnviar){
          subirImagen(acreditEnviar,"Acreditación")
       }
      }else{
        setMensaje("Seleccione una imagen para Nombramiento,Nombramiento 2 o Acreditación");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }


  return(
  <Modal
    titulo={titulo} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={handleSubmission}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} btnDeshabilitado={btnDeshabilitado}
    setAviso={setAviso} tamanio={'l'} cargando={espera}       
  >
    <form className={classes.root} noValidate autoComplete="off"> 
      <Card className={classes.cardDatos} style={{marginRight:'1.5rem'}}>
        <Typography id="primaria" style={{textAlign:'center'}}><b>Nombramiento</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgPrimaria}  
            src={imgPrimaria ? imgPrimaria : ""} alt="Imagen Primaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgPrimaria(e)} hidden id={"imgPrimaria"}
              accept="image/jpg,image/jpeg,image/png"disabled={botonesDeshab}  />
            <label htmlFor={"imgPrimaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
      <Card className={classes.cardDatos} style={{marginRight:'1.5rem'}}>
        <Typography id="secundaria" style={{textAlign:'center',}}><b>Nombramiento 2</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgSecundaria}  
            src={imgSecundaria ? imgSecundaria : ""} alt="Imagen Secundaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgSecundaria(e)} hidden id={"imgSecundaria"}
              accept="image/jpg,image/jpeg,image/png" disabled={botonesDeshab}  
            />
            <label htmlFor={"imgSecundaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
      <Card className={classes.cardDatos}>
        <Typography id="secundaria" style={{textAlign:'center'}}><b>Acreditación</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgAcreditacion}  
            src={imgAcreditacion ? imgAcreditacion : ""} alt="Imagen Secundaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgAcreditacion(e)} hidden id={"imgAcreditacion"}
              accept="image/jpg,image/jpeg,image/png" disabled={botonesDeshab}  
            />
            <label htmlFor={"imgAcreditacion"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
    </form>
  </Modal>
  )
}
