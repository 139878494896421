import React,{useEffect,useState} from 'react';
import {PolarArea,Bar} from 'react-chartjs-2';
import {Card,Box, } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:10+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
 
}));

export default function GraficaPolar({valor}){
  const classes = useStyles();
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);

  const [dataH,setDataH]= useState([]);
  const [dataM,setDataM]= useState([]);



  const [bandera,setBandera]= useState(false);
  useEffect(()=>{    
    //console.log(valor);
   const{  
      TpObsvr, Valido, Pendiente, Rechazado,
      //TpRcGenM ,TpRg, TpRc,     
      //TpObsvrGenH, TpObsvrGenM,
    //  TpRgGenH, TpRgGenM, TpRcGenH,TpRcGenM 
    }=valor            



    //setDataH({Obsvervador:TpObsvrGenH,Rg:TpRgGenH,Rc:TpRcGenH})
    //setDataM({Obsvervador:TpObsvrGenM,Rg:TpRgGenM,Rc:TpRcGenM})

    setData([TpObsvr,Valido, Pendiente, Rechazado, // TpRg , TpRc, 
    ])

    setBandera(true) 
  },[valor])

  const data2 = {
    labels: ['Obsvervador','Validado', 'Pendiente','Rechazado',// 'Rg', 'Rc' 
    ],
    datasets: [
    { type: 'polarArea', label:'Avance',
        data:data,//[10, 20, 30, 40],
        backgroundColor: [auxColor,"#58b85c","#FBBE06 ","#E91E63"] //"#4BAC7D50"
      },         
     
      /* { label:'Hombre Total',
        type: 'line',
        data: dataH, //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },
      { label:'Mujer Total',
        type: 'line',
        data: dataM, //[50, 40, 30, 50],
        backgroundColor:'#C52363',
        borderColor: '#C52363',
        //fill: false,
        tension: 0.4           
      },   */
 
    ],
  }; 

  const options = {
  
  //  indexAxis:'y',
    tooltips: {
      enabled: true,
    },
   /*  layout: {
      padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
      }
    }, */
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
   /*  scales: {
      x: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
        labe :{
          fontSize:6,
        },
      }
    
    }, */
  } 

  function  llenadoTabla2(datos,nombre)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:25.5+'rem',width:25+'rem'}} >              
      <PolarArea
        id="graficaMuni1"
        data={datos}
        width={400} height={200} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1}  >           
      <Box display="flex"className={classes.contenedorCard}  >
        {data ?llenadoTabla2(data2,'Perfiles'):null}
      </Box>    
        
  </Box>
  );
}