import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography,
        TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalNvoEdit from './ModalNvo';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ModalFoto from './ModalFoto';
import CreateIcon from '@material-ui/icons/Create';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ModalMapa from './ModalMapa';
import ModalAgregarEvidencia from './ModalAgregarEvidencia';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ModalFotografia from './ModalFotografia';
import PaginacionTab from '../layout/PaginacionTab'
import CardPerfil  from './CardPerfil';
import moment from 'moment';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import { exportarArch } from '../funciones/ListaExcel';
import SaveIcon from '@material-ui/icons/Save';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '86rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '84rem',
      minHeight: window.innerHeight-190,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '84rem',
        maxHeight: window.innerHeight-250,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[              
    {
      id:1, titulo:"Tipo", alinear:'center'
    },     
    {
      id:12, titulo:"FRegistro", alinear:'left'
    }, 
    {
      id:2, titulo:"Nombre", alinear:'left'
    }, 
    {
      id:3, titulo:"Edad", alinear:'center'
    },
    {
      id:4, titulo:"Teléfono", alinear:'center'
    }, 
    {
      id:5, titulo:"Sección", alinear:'center'
    },  
    {
      id:6, titulo:"Municipio",alinear:'center'
    }, 
    {
      id:11, titulo:"Distrito",alinear:'center'
    },
    {
      id:7, titulo:"Participó", alinear:'center'
    },                 
    {
      id:8, titulo:"Genero", alinear:'center'
    },
    {
      id:9, titulo:"Clave de Elector", alinear:'center'
    }, 
    {
      id:10, titulo:"Email", alinear:'center'
    },  
    
    
  ];

  const archivo=[{id:0,part:"fm", dir:"partido_fm.svg"},
    {id:1,part:"individual", dir:"partido_individual.png"},
    {id:2,part:"mc", dir:"partido_mcu.svg"},
    {id:3,part:"morena", dir:"partido_mor.jpg"},
    {id:4,part:"morptna", dir:"partido_morptna.png"},
    {id:5,part:"na", dir:"partido_na.svg"},
    {id:6,part:"nulo", dir:"partido_nulo.png"},
    {id:7,part:"pan", dir:"partido_pan.svg"},
    {id:8,part:"panpriprd", dir:"partido_panpriprd.png"},
    {id:9,part:"pes", dir:"partido_pes.svg"},
    {id:10,part:"prd", dir:"partido_prd.svg"},
    {id:11,part:"pri", dir:"partido_pri.svg"},
    {id:12,part:"pt", dir:"partido_pt.svg"},
    {id:13,part:"ptmorena", dir:"partido_ptmorena.png"},
    {id:14,part:"pvem", dir:"partido_pvem.svg"},
    {id:15,part:"rsp", dir:"partido_rsp.svg"}]

const alturaCont = window.innerHeight<937 ? window.innerHeight-160 : window.innerHeight-180

export default function TablaRegional({muni,listMuni, listAllSecc,listSeccDto, llamaListSeccDto,listPart,getDatos,totalPerfil}){    
  const classes = useStyles();    
  const auxCol=process.env.REACT_APP_Fondo_Color
  const nvl= localStorage.getItem('Tipo')?parseInt(localStorage.getItem('Tipo')):0;      
  const [promotor, setPromotor] = useState({nombre:""})
  const [seleccion, setSeleccion] = useState(null)
  const [datos,setDatos] = useState([]);     
  const [modalNvo, setModalNvo] = useState(false);  
  const [modalFoto, setModalFoto] = useState(false);  
  const [actualizar, setActualizar] = useState(false);  
  const [modalMap, setModalMap] = useState(false);  
  const [modalEvidencia, setModalEvidencia] = useState(false);  
  const [totalPaginas,setTotalPaginas]=useState(1);
  const [numPagina,setNumPagina]=useState(0);
  const limiteRow=150
  const {nombre} = promotor;  
  const[arrExcel,setArrExcel]=useState([]);
    useEffect(()=>{
      if(muni.length>limiteRow)
      {   let auxTam = muni.length;
          if(auxTam%limiteRow===0)
          {
              let pagina=Math.trunc(auxTam/limiteRow)
              setTotalPaginas(pagina)   
          }
          else
          {   let pagina=Math.trunc(auxTam/limiteRow)
              pagina=pagina+1;
              setTotalPaginas(pagina)
          }
          setNumPagina(0) 
          setDatos(muni.slice(0,limiteRow))
      }
      else
      {   setTotalPaginas(1)
          setNumPagina(0)
          setDatos(muni)
      }
      let auxExcel=[]
      
      auxExcel.push(["#","Tipo",	"FRegistro",	"Nombre",	"Edad",	"Teléfono",	"Sección",	"Municipio",	"Distrito",	"Participó",	"Genero",	"Clave de Elector", "Email"])
      muni.forEach((reg,index)=>{
        const auxi3=[index+1,reg.StNom,(reg.Fch ?
          (moment.utc(reg.Fch).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc(reg.Fch).format('DD/MM/YYYY HH:mm')) :""),
          `${reg.Nom } ${reg.Pat }  ${reg.Mat }`,reg.Edad, reg.Tel,reg.SecNum,
          reg.MunNom, reg.DtoNom, reg.PartNom, reg.Genero, reg.Ine, reg.Email]
        auxExcel.push(auxi3);  
      })
       // setDatos(muni)
       setArrExcel(auxExcel)
        //la siguiente linea puede generar errores o no en el useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[muni])          

    const onChangeTable =(regis)=>{                       
        setSeleccion(regis)
    }

    function filtro(){        
      let auxCadena=nombre.trim();       
      let info=[];                        
      let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
      info=aux 
      let auxNum=0;
        if (aux!==null||aux!=='') 
        {
        aux.forEach((mun)=>
        { if (mun.nombreReg!=='EDOMEX')
          {   
            auxNum++;
          }                
        })
      }         
      return TablaEstr(info);
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
            && !expMas.test(e.target.value)) || e.target.value===' '||e.target.value==='') 
        {
            setPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
    };

    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }
    
  const celdaNvo=()=>{
    return(
    <Tooltip title={`Nuevo `}>
      <IconButton aria-label="agregar nuevo" onClick={() => setModalNvo(true)} 
        component="span" size="small" 
      >
        <AddCircleOutlineIcon style={{color:"white" }} />  
      </IconButton>
    </Tooltip> 
    )
  }

  const partidoFun=(regis)=>{
  //  console.log(regis);
    const filtro =archivo.filter((partido)=>partido.part.toUpperCase() === regis.PartNom.toUpperCase())
    let respues=null
    if (filtro[0]&& filtro[0].length!==0) 
    {
      respues=<img src={`./partidos/${filtro[0].dir}`} style={{width:30,marginTop:".1rem" }} />
    }
    else
    { respues=null//<img src={`./partidos/partido_na.svg`} style={{width:30 }} />
    }
    return respues
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

    
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 
  
  const CeldaIco=({children,onChange,tooltip,aria,estiloIcono})=>{
    return<Tooltip title={tooltip}>
      <IconButton aria-label={aria} onClick={()=>onChange(true)} 
        component="span" size="small" style={estiloIcono?estiloIcono:{}}
      >              
        {children}
      </IconButton>
    </Tooltip>
  }
/*  nvl===1 ?<CeldaIco estiloIcono={{width:'20px', height:'20px'}} 
                aria="Exportar Observadores" onChange={()=>exportarArch(arrExcel," Observadores")} tooltip={`Exportar Observadores`} >
                <SaveIcon style={{color:"white"}}/>
              </CeldaIco>              
              :"" */
  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
            <CelTitl index={1} />
            <CelTitl index={2} />            
            <CelTitl index={5}  />                
            <CelTitl index={5} titulo={auxDatos.length} />                        
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>                                  
        {auxDatos.map((elemnto,index) => {  
          const auxVal=seleccion?.Id === elemnto.Id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index} onClick={ () => onChangeTable(elemnto) }  
          >   
            {celda("center", {width:"2rem"}, 
             nvl===1? 
             (<CeldaIco onChange={setModalFoto}//setModalFoto
             tooltip={elemnto.Fotos!==0?`Galeria `:"Validar"} 
              aria="Galeria">             
              {elemnto.Fotos!==0?  
                <PhotoLibraryIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
                : <EditAttributesIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />
              }              
              </CeldaIco>
            )
            : (elemnto.Fotos!==0?  
                (<CeldaIco onChange={setModalFoto} tooltip={`Galeria `} aria="Galeria">
                  <PhotoLibraryIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />                  
                </CeldaIco>
                )
              :null
            )
            ,index+1) }   
            {celda("center", {width:"2rem"}, 
              <CeldaIco onChange={setModalEvidencia} tooltip={`Agregar Evidencia `} aria="agregar Evidencia">
                <AddPhotoAlternateIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </CeldaIco>               
            ,index+2) } 
            {celda("center", {width:"2rem"}, 
              elemnto.Lat!==0&&elemnto.Lon!==0?
              <CeldaIco onChange={setModalMap} tooltip={`Mapa `} aria="agregar nuevo">
                <LocationOnIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </CeldaIco>
              :null
            ,index+3) }   
            {celda("center", {width:"2rem"}, 
              <CeldaIco onChange={setModalNvo} tooltip={`Editar `} aria="editar registro">
                <CreateIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </CeldaIco>            
            ,index+4) }      
            {/* celda("center", {width:"2rem"}, elemnto.Id,index+5)  */}                                 
            {tipoEdoProspecto(elemnto.StColor,elemnto.StNom)}        
            {celda("center", {fontSize:".7rem", paddingRight:".6rem"},elemnto.Fch ?
                  (moment.utc(elemnto.Fch).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                  ? "" : moment.utc(elemnto.Fch).format('DD/MM/YYYY HH:mm')) : null,index+7) }            
            {celda("left", {fontSize:".7rem"},`${elemnto.Nom } ${elemnto.Pat }  ${elemnto.Mat }`,index+7) }            
            {celda("center", {width:"3rem"}, elemnto.Edad,index+8) }  
            {celda("center", {width:"3rem"}, elemnto.Tel,index+9) }    
            {celda("center", {width:"4rem"}, elemnto.SecNum,index+10) }
            {celda("center", {fontSize:".7rem"}, elemnto.MunNom,index+11) }    
            {celda("center", {fontSize:".7rem",width:"3.5rem"}, elemnto.DtoNom,index+12) }   
            {celda("center", {width:"4rem"}, 
              elemnto.PartId!==0? partidoFun(elemnto):""
            ,index+12) }         
            {celda("center", {}, elemnto.Genero,index+13) }      
            {celda("center", {fontSize:".7rem"}, elemnto.Ine,index+14) }      
            {celda("left", {fontSize:".7rem"}, elemnto.Email,index+15) }                                                         
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
  
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {   setDatos(muni.slice(0,limiteRow))   
    }
    else
    {   setDatos(muni.slice(newPage * limiteRow, newPage * limiteRow + limiteRow))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false}
      style={{padding:0}} rowsPerPage={limiteRow}
      count={muni.length} page={numPagina}
      lang='es'  onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}
      labelDisplayedRows={
          ({ from, to, count }) => {
          return '' + from + '-' + to + ' de ' + count
          }
      }
    />)
  }                   

  const modMap = modalMap ? <ModalMapa seleccionado={seleccion} titulo={"Mapa Registro"} subtitulo={""}
                                    setModalAbierto={setModalMap } modalAbierto={modalMap}/> : null;   
    const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
    const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}><Typography variant="h4" component="h3">No contiene datos</Typography></Box>)
    const modNvo = modalNvo ? 
                        <ModalNvoEdit modalAbierto={modalNvo} setModalAbierto={setModalNvo} getDatos={getDatos}
                            titulo="Editar Registro" listMun={listMuni} listSecc={listAllSecc}   
                            listPart={listPart} auxReg={seleccion}  listSeccDto={listSeccDto} llamaListSeccDto={llamaListSeccDto}         
                        />:null
  const modFoto =modalFoto ?<ModalFoto modalAbierto={modalFoto} setModalAbierto={setModalFoto} getDatos={getDatos}
                              titulo={`${seleccion.Fotos!==0?'Fotografias':'Validar'} ${seleccion.Nom } ${seleccion.Pat }  ${seleccion.Mat }`} 
                              seleccionado={seleccion}                                
                            />:null                                                                                        
  /* const modEvidenc = modalEvidencia ? <ModalAgregarEvidencia abrirModal={modalEvidencia} setAbrirModal={setModalEvidencia} registro={seleccion}  
                                    setUpdate={getDatos} />                           
                                :null  */                                                            
  
  const modEvidenc = modalEvidencia ? <ModalFotografia modalAbierto={modalEvidencia} setModalAbierto={setModalEvidencia} seleccionado={seleccion}  
                                        titulo={`Subir Evidencia ${seleccion.Nom } ${seleccion.Pat }  ${seleccion.Mat }`} setActualizar={setActualizar} getDatos={getDatos}
                                        actualizar={actualizar} 
                                      />                           
                                :null                                
  const paginacion = totalPaginas>1 ? generarFooter() : null;                            
  return (
  <Box display={'flex'} flexDirection="column" style={{height: alturaCont}}>
    <Box  display={'flex'} mb={1}>    
      {totalPerfil.length !== 0 ? 
        <CardPerfil valor={totalPerfil} />
      :null}     
      {paginacion}   
    </Box>
    
    <Card className={classes.estiloCard} style={{height:window.innerHeight-220}}>                        
      
      {llamada}   
    </Card>
    {modEvidenc}
    {modMap}
    {modNvo}
    {modFoto}
   
  </Box>                    
  );
}