import React,{useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
      height:3.5+'rem',
      width:130,
     // padding:'1.1 rem 0px 1.12rem',
      marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'.8rem',
    },
}));


export default function CardTotal({valor,dtoId}){
    const classes = useStyles();
    const[total,setTotal]= useState([])
    const auxColor=process.env.REACT_APP_Fondo_Color
    React.useEffect(()=>{
      //  console.log(valor,dtoId);

        let auxGrNo=valor.avc_gral[0].TpObsvr 
        let auxGrRG=valor.avc_gral[0].TpRg
        let auxGrRC=valor.avc_gral[0].TpRc

        let auxGrValido=valor.avc_gral[0].Valido
        let auxGrPendiente=valor.avc_gral[0].Pendiente
        let auxGrRechazo=valor.avc_gral[0].Rechazado

        let auxDto=valor.avc_gral[0].UsrDto 
        let auxMun=valor.avc_gral[0].UsrMun
        let auxSec=valor.avc_gral[0].UsrSec

        if (dtoId&& dtoId!==0) {
            const filtrado =valor.avc_dto.filter((mun)=>mun.Id===dtoId)
            auxGrNo=filtrado[0].TpObsvr 
            auxGrRG=filtrado[0].TpRg
            auxGrRC=filtrado[0].TpRc  

            auxGrValido= filtrado[0].Valido
            auxGrPendiente= filtrado[0].Pendiente
            auxGrRechazo= filtrado[0].Rechazado          
        }
       /*  const auxUsrNo=valor[0].AvcUsrNo
        const auxUsrRG=valor[0].AvcUsrRg
        const auxUsrRC=valor[0].AvcUsrRc
        */
        const auxGralDto=valor.avc_gral[0].AvcDto 
        const auxGralMun=valor.avc_gral[0].AvcMun 
        const auxGralSec=valor.avc_gral[0].AvcSecc      
        const auxTotDto=valor.avc_gral[0].TotalDto 
        const auxTotMun=valor.avc_gral[0].TotalMun 
        const auxTotSec=valor.avc_gral[0].TotalSecc        
        setTotal({GrRG:auxGrRG,GrRC:auxGrRC,GrNo:auxGrNo,
            grValido: auxGrValido,grPendiente:auxGrPendiente,
            grRechazo:auxGrRechazo,
            //UsrRG:auxUsrRG,UsrRC:auxUsrRC,UsrNo:auxUsrNo,
            gralMun:auxGralMun,gralSec:auxGralSec,
            totMun:auxTotMun,totSec:auxTotSec,
            gralDto:auxGralDto, totDto:auxTotDto,
            usrDto:auxDto, usrMun:auxMun, usrSec:auxSec
        })
    },[valor,dtoId])  

    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }

    function generarCards2(mensaje,datGen,datTot,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datGen} displayType={'text'}  thousandSeparator={true}/>
                {' / '}
                <NumberFormat value={datTot} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
  
    return ( 
        
    <Box display="flex" flexDirection="row" mb={1}  >   
        {total.length!==0 ? 
        <Box display="flex" flexDirection="row"> 
            <Card className={classes.card}>
                {generarCards2('Distrito',total.gralDto, total.totDto,auxColor)}
            </Card>                                              
            <Card className={classes.card}>
                {generarCards2('Municipio',total.gralMun, total.totMun,auxColor)}
            </Card>
            <Card className={classes.card}>
                {generarCards2('Sección',total.gralSec, total.totSec,auxColor)}
            </Card>
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO Valido':'Gral Valido',total.grValido,'#58b85c')}
            </Card>
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO Pendiente':'Gral Pendiente',total.grPendiente,'#FBBE06')}
            </Card>
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO Rechazado':'Gral Rechazado',total.grRechazo,'#E91E63')}
            </Card>
            {/*<Card className={classes.card}>
                {generarCards(dtoId? 'DTO RG':'General RG',total.GrRG,'#58b85c')}
            </Card> */}
            {/* <Card className={classes.card}>
                {generarCards(dtoId? 'DTO RC':'General RC',total.GrRC ,'#4295f5')}
            </Card> */}
            <Card className={classes.card}>
                {generarCards(dtoId? 'DTO OBS':'General OBS', total.GrNo,auxColor)}
            </Card> 
            <Card className={classes.card}>
                {generarCards('Coord Dto',total.usrDto,auxColor)}
            </Card>    
            <Card className={classes.card}>
                {generarCards('Coord Mun',total.usrMun,auxColor)}
            </Card>
            <Card className={classes.card}>
                {generarCards('Organización',total.usrSec,auxColor)}
            </Card>
        </Box>   
        :null}
    </Box>
    );
}
 