import React,{useEffect, useState} from 'react';
import {Box,LinearProgress, Typography,Checkbox,FormControlLabel} from "@material-ui/core";
import MapaMarcadores from './MapaConsultaMarcador';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import Brightness1Icon from '@material-ui/icons/Brightness1';
import TablaObserv from './TablaObserv';
export default function MapaPanelIncidencia ({estadoId,munId,munSelect}){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const usuid =localStorage.getItem('UsuId') ; 
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";         
    const [espera,setEspera] = useState(true)
    const [poliLocal,setPoliLocal] = useState([])
    const [centro,setCentro] = useState({lat: 19.493815, lng: -99.1137317});
    const [cambiaMarcador,setCambiaMarcador] = useState(true);    
    const [banderaError,setBanderaError] = useState(""); 
    const [listMapProm,setListMapProm] = useState([])    
    const [listApoyos] = useState([]) 
    const [listRecorrido] = useState([]) 
    const [listObserv,setListObserv] = useState([])     
    const [checked, setChecked] = useState(false);    
    const [Observadores, setObservadores] = useState(false);
    
    useEffect(()=>{        
               
        getPoligonos();                
        limpiaCheck()       
        return ()=> {
            source.cancel();
        }            

    },[munId])

    useEffect(()=>{                                              
        
        if (checked) 
        {
            llamaMapAvance()    
        }else{
            setListMapProm([])
        }        

    },[checked])
    
    useEffect(()=>{                                              
        
        if (Observadores) 
        {
            llamaMapObserv()    
        }else{
            setListObserv([])
        }        

    },[Observadores,munId])

    const limpiaCheck=()=>{
        setChecked(false)                
    }

    const llamaMapAvance = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "perfil-mapa-avance";

        function respuesta(auxiliar) {
            console.log(auxiliar);
            if (auxiliar[0].Id != -1) {
                return setListMapProm(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaMapObserv = () => {        
        const data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:munId,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        const url = "incidencia-mapa-avance";

        function respuesta(auxiliar) {
            console.log(auxiliar);
            if (auxiliar[0].Id != -1) {
                return setListObserv(auxiliar)
            }else{
                return setListObserv([])
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const onChangeObserv = () => {
        setObservadores(!Observadores)
    };

    const getPoligonos = ( ) => {
        setPoliLocal([])
        setCentro([])
        const data = qs.stringify({
            Usuario: usuid, Edo:estadoId,
            Muni:munId, idDispositivo: 'csdq21weddewde' 
        });
        
        const url="incidencia-mapa-edo-muni-secc"
        function respuesta(auxiliar) 
        {      // console.log(auxiliar);         
            if (auxiliar[0]?.Id !== -1 &&auxiliar[0].gps ) 
            {   
                setPoliLocal(auxiliar);   
                let auxContenido=false,auxCoord=[]
                auxiliar.forEach(element => {
                    if (!auxContenido&& element.gps &&element.gps[0].Lat!==0&&element.gps[0].Lon!==0) 
                    {
                        auxContenido=true
                        auxCoord=generaCoordenadaDinam(element.gps[0].Lat, element.gps[0].Lon) 
                    }
                });
                             
                setCentro(auxCoord)   
                if (auxContenido) 
                {   setBanderaError("")         
                }
                else
                {   setBanderaError("Municipio sin Coordenadas")     
                }
                
            }  
            else{
                setBanderaError("Municipio sin Coordenadas")     
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'70%'}} > 
                                    <LinearProgress/> 
                                </Box>) :banderaError.length===0? 
                                    <MapaMarcadores poligonos={poliLocal}  centro={centro} 
                                        cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador}     zoom={12.5}                                 
                                        listMapProm={listMapProm} listApoyos={listApoyos} listRecorrido={listRecorrido}
                                        listObserv={listObserv}
                                    />
                            :<Typography>{banderaError}</Typography>
                            ;
    
    return (
    <div style={{display:'flex',justifyContent:'center',marginTop:'.5rem'}}>    
    <Box>
        <Box display="flex">
  
             <Box display="flex" alignItems="center" ml={3} >
                <FormControlLabel
                    disabled={espera} label="Incidencias" size={'small'}
                    control={<Checkbox color="primary" checked={Observadores} name={"Observadores"}/>}
                    labelPlacement="end" onChange={onChangeObserv} 
                />           
                <Brightness1Icon fontSize="small" color="primary" />
            </Box>
     {/*        <Box display="flex" alignItems="center" ml={15} >
                <Typography>Sin Observador</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#fb8006",  opacity: 0.7}} />
            </Box>
            <Box display="flex" alignItems="center" ml={15} >
                <Typography>Duplicados</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#E61423",  opacity: 0.7}} />
            </Box>
            <Box display="flex" alignItems="center" ml={15} >
                <Typography>Con Observador</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#42CE00",  opacity: 0.7}} />
            </Box> */}
        </Box>
        <Box display={"flex"}>
            {elementos}
            {espera ? 
                null 
            :   listObserv.length!==0? 
                    <TablaObserv listObserv={listObserv} estadoId={estadoId} munId={munId} auxSec={0} />
                :Observadores ?<Typography style={{marginLeft:".5rem"}}>Sin Incidencias registradas</Typography>:null}
        </Box>
        
        
    </Box>
        
    </div>
    );
}
