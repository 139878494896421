import React, {useState, useEffect} from 'react';
import {Box,Grid, Card, DialogContent, LinearProgress, 
  Typography, Paper, DialogActions, Button, CircularProgress} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import ModalInformativo from '../generales/ModalInformativo';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import Viewer from 'react-viewer';
import {toast} from "react-toastify";
import { green, red } from '@material-ui/core/colors';
import {findNumberCad} from '../funciones/FindNumber'
const useStyles = makeStyles((theme) => ({
  imageSize: {
    //width: '50%', 
    height: '20vh'
}, container: {
    overflowY: 'auto', maxHeight: window.innerHeight - 240, marginTop: '.5rem'
}, content: {
    padding: '0!important', margin: 0
},   buttonGuardar:{color: green[500],},
    buttonRechazar:{color: red[500],},
  }));

export default function ModalFoto({titulo,modalAbierto,setModalAbierto,seleccionado,getDatos}){
  const classes = useStyles();  
  const history = useHistory();
  const source = axios.CancelToken.source(); 
  const auxId =localStorage.getItem('UsuId') ;  
  const Dispvo =localStorage.getItem('Dispvo') ;    
  const auxDispV =Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'
  const opera = localStorage.getItem("Operacion")+"|";
  const [listFoto, setListFoto] = useState([]);  
  const [bandera, setBandera] = useState(false);  
  const[espera,setEspera]=useState(false);
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);  
  const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);    
  useEffect(()=>{                 
    llamadaListFoto(seleccionado.Id)

    return ()=> {
      source.cancel();
  }
  },[seleccionado]);

  function llamadaListFoto(auxLlave)  {            
    const auxU= `estructura-evidencia-list`;                            
    const dataInfo = qs.stringify({                
        Usuario:auxId,             
        Llave:auxLlave,             
        idDispositivo:auxDispV,
        });
             
    function respuesta (aux) 
    { if (aux[0].Id !== -1) 
      {                                                    
        setListFoto(aux)
        setBandera(true)
      } 
      else
      { setBandera(false)
      }                                                           
    }
    llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
}; 

const handleViewer = (index) => {
  setVisible(true)
  setIndex(index)
}
const llamadaNvoStd = (auxStd) => {  
  const data = qs.stringify({
      Usuario: auxId, Llave:seleccionado.Id, Status: auxStd,idDispositivo: auxDispV,
  });
  const url = "estructura-valida";

  function respuesta(auxiliar) {
    if (auxiliar.respuesta !== -1) 
    {      
      const mensaje = "Se actualizo el Estatus"
      toast.success(mensaje, {
        pauseOnFocusLoss: false,
        toastId: `edit-val${seleccionado.Id}`
      })
      getDatos()
      setModalAbierto(false)
      setBtnDeshabilitado(true)            
    }
  }    
  llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
}   

function tipoEdoProspecto(auxColorStd, etiqueta) {
  const renglon = (
    <Card style={{
      color: auxColorStd === "#EBF1F8" ? "black" : 'white', fontWeight: "bold",
      backgroundColor: auxColorStd, textAlign: 'center'
    }}>
      {etiqueta}
    </Card>
  )
  return renglon
}

const cambiarStatus = (e, auxValor) => {
  e.preventDefault()
  //console.log(auxValor);
  llamadaNvoStd(auxValor)  
}

const cardPerfil3 = (  
    <Box    style={{ alignContent: 'center', textAlign: 'center' ,width:"15rem"}}>
      <Box display={"flex"}>
      <Typography style={{marginRight:".5rem"}}>Estado Actual</Typography>
      <Typography style={{color:seleccionado.StId===0? "#FBBE06" :seleccionado.StColor}}>
        {seleccionado.StId===0? "Pendiente" :seleccionado.StNom}</Typography>
      </Box>      
      <Card style={{ marginTop: '1rem' }}>
        {   seleccionado.StId===0 || seleccionado.StId===1 ?      
          <DialogContent >
            {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
            <Typography className={classes.margin} variant="subtitle2"> Seleccione una opción </Typography>
            {bandera ?
              <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  } //disabled={espera ? true : false}
                  variant="outlined" style={{  marginRight: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                  className={classes.buttonRechazar}
                >
                    Rechazar
                </Button>
                <span className={classes.wrapper}>
                <Button onClick={e => cambiarStatus(e, 2)} variant="outlined" disabled={btnDeshabilitado? true : espera  }//disabled={espera ? true : false}
                  style={{   marginLeft: 'auto', maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}                                        
                  className={classes.buttonGuardar}
                >
                  Validar
                </Button>
                {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
              </DialogActions>
              :
              <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                <span className={classes.wrapper}>
                  <Button onClick={e => cambiarStatus(e, 3)} disabled={btnDeshabilitado? true : espera  }
                    variant="outlined" color="error" style={{   maxWidth: '90px', maxHeight: '35px', minWidth: '90px', minHeight: '35px' }}
                    className={classes.buttonRechazar}
                  >
                    Rechazar
                  </Button>
                  {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                </span>
              </DialogActions>
            }              
          </DialogContent>        
          : auxId && parseInt(auxId) === 1 ?         
            <DialogContent >
              {tipoEdoProspecto(seleccionado.StatusColor, seleccionado.StatusNom)}
              <Typography className={classes.margin} variant="subtitle2"> ¿Desea cambiar su Estatus?</Typography>
                
              <DialogActions style={{ alignContent: 'center', textAlign: 'center', justifyContent: 'center', 
                padding: 0, marginTop: '1rem', marginBottom: '1rem' }} >
                  <span className={classes.wrapper}>
                  <Button onClick={e => cambiarStatus(e, 1)} disabled={btnDeshabilitado? true : espera  }
                    variant="outlined"  style={{ fontWeight:800,   maxHeight: '35px', minWidth: '90px', 
                    minHeight: '35px', backgroundColor:"#FBBE06" ,color:"white"}}                    
                  >
                    Pendiente
                  </Button>
                  {espera && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </span>
              </DialogActions>                                                   
            </DialogContent>         
            :null                        
          }
      </Card>
    </Box>
 
)
const viewer = <Paper square>
                <Viewer
                  visible={visible} onClose={() => { setVisible(false); }}
                  images={listFoto.map((element) => {
                    return ({src: element.Dir + element.Archivo, alt: element.Descr})
                  })}
                  activeIndex={index} zIndex={2000}
                />
                </Paper>     
  return (
  <ModalInformativo titulo={titulo}
    setModalAbierto={setModalAbierto} modalAbierto={modalAbierto} maxTam={false} 
    tamanio={listFoto.length === 3 ?'lg':listFoto.length === 1 || seleccionado.Fotos===0 ?"sm": 'md'} 
  > 
    {viewer}
    <Box display="flex" justifyContent="center" >
    {
      listFoto.length !== 0 ? 
        listFoto.map((foto,index)=>
          <span style={{marginRight:"1rem"}}>
            <Typography>{foto.Tipo}</Typography>
            <img src={foto.Dir+foto.Mini} onClick={() => handleViewer(index)}
              style={{marginBottom:"1rem"}} 
              id={index} key={index}  className={classes.imageSize} />
          </span>
        )
      : (espera ? <LinearProgress/> 
        : <Box style={{alignItems:"center", display:"flex"}}><Typography style={{paddingRight:"2rem"}}>Registro sin Evidencia</Typography></Box>)
    }
    {findNumberCad('|23|',opera) ? cardPerfil3 :null}
    </Box>
  </ModalInformativo>
  )
}