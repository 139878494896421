import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '11rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'.5rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '10rem',
      minHeight: "39rem",
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '10rem',
        maxHeight: "39rem",
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[                    
    { id:1, titulo:"Distrito", alinear:'center' },      
    { id:2, titulo:"Sección", alinear:'center' },      
  ];

const alturaCont = window.innerHeight<937 ? window.innerHeight-120 : window.innerHeight-160

export default function TablaDto({listDistrito,registro, setRegistro}){    
  const classes = useStyles();    
  const [promotor, setPromotor] = useState({nombre:""})
  const [seleccion, setSeleccion] = useState(null)
  const [datos,setDatos] = useState([]);     

  const auxCol=process.env.REACT_APP_Fondo_Color
  const {nombre} = promotor;    
  useEffect(()=>{

    setDatos(listDistrito)

    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listDistrito])          

  const onChangeTable =(regis)=>{                       
    setRegistro(regis)
  }

  function filtro(){        
    let auxCadena=nombre.trim();       
    let info=[];                        
    let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
    info=aux 
    let auxNum=0;
      if (aux!==null||aux!=='') 
      {
      aux.forEach((mun)=>
      { if (mun.nombreReg!=='EDOMEX')
        {   
          auxNum++;
        }                
      })
    }         
    return TablaEstr(info);
  }


  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
        
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody> 
        <TableRow className={classes.tableRow} selected={ registro?.Id === 0} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={0} onClick={ () => onChangeTable({Id:0,Num:0}) }  
        >   
         
          {celda("center", {width:"2rem"}, "GENERAL",0) }        
          {celda("center", {width:"2rem"}, "",0) }            
        </TableRow>
        {auxDatos.map((elemnto,index) => {  
          const auxVal=registro?.Id === elemnto.Id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index+1} onClick={ () => onChangeTable(elemnto) }  
          >   
         
            {celda("center", {width:"2rem"}, elemnto.Num,index+1) }                                 
            {celda("center", {width:"2rem"}, elemnto.Total,index+1) }                                 
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
    const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
    const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}>
                                    <Typography variant="h5" component="h5">No contiene datos</Typography>
                                    </Box>)
                                                                                
                           
                                
  return (
  <Box display={'flex'} flexDirection="row" style={{height: "40rem"}}>
    <Card className={classes.estiloCard} style={{height:"40rem"}}>                        
         
      {llamada}   
    </Card>

  </Box>                    
  );
}