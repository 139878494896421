import React,{useState} from 'react';
import {Box, LinearProgress, } from "@material-ui/core";
import InciGrafica from './InciGrafica';
import InciTablaTipo from './InciTablaTipo';
import InciTablaRes from './InciTablaResultados';
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken, ErrorGeneral, } from '../funciones/ClienteAxios';
import { useHistory } from "react-router-dom";
export default function InciPanel({listaTp,listTipo,setActualizar,actualizar,listMuni,recargar}){
    const history = useHistory();
    const source = axios.CancelToken.source(); 
    const usuid =localStorage.getItem('UsuId') ;  
    const [resTipo,setResTipo] = useState([])
    const [selec,setSelec] = useState(-1)
    const [objSelec,setObjSelec] = useState([])     
    const [espera, setEspera] = useState(false)
    const [tipoSelect, setTipoSelect] = useState(null)
    function getResSel(numTipo){
                                                       
        const url = "incidencia-list-xtipo";                                              
        const data = qs.stringify({                      
            Usuario:usuid,                      
            Tipo:numTipo,
            idDispositivo:'89wuuidsddsfe'      
        });                        
        function respuesta(aux) {
            if (aux) 
            {                
                setResTipo(aux);
            }else{
                setResTipo([])
            }   
        }        
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);                            
         
    }

    return(
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={{marginBottom:'1em'}}>
            <InciTablaTipo listaTp={listaTp} getResSel={getResSel} actualizar={actualizar} 
                setActualizar={setActualizar} setSelec={setSelec} setObjSelec={setObjSelec} 
                listMuni={listMuni} recargar={recargar} 
            />
            <InciGrafica valor={listaTp} descripcion="Gráfica de Incidencias" />
        </Box>
        <Box>
            {espera? <Box width={"70%"}><LinearProgress/></Box>
            :   <InciTablaRes listTipo={listTipo} lista={resTipo} objSelec={objSelec}  getResSel={getResSel} 
                    tipo={tipoSelect} setTipo={setTipoSelect}
            />
            }
        </Box>
    </Box>
    )
}